import React from 'react';
import * as R from 'ramda';

import { getSDDOTReplenishmentSubtextOptions } from '@atom/components/common/requests/customTenantUtilities';
import { Button, Icon, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { ReplenishmentCreateInput } from '@atom/types/assetRequest';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './sddotView.css';

const DECIMAL_LIMIT = 4;

const styles = {
  label: {
    color: colors.neutral.dim,
  },
  currencyIcon: {
    marginRight: '2px',
    marginBottom: '2px',
    color: colors.neutral.dim,
    fontSize: '16px',
  },
  icon: {
    marginRight: '1rem',
  },
  largeInput: {
    width: '12rem',
  },
  smallInput: {
    width: '7rem',
  },
};

interface Props {
  assetUnit: string;
  totalOrdered: number;
  totalReceived?: number;
  replenishmentInputs: Partial<ReplenishmentCreateInput>;
  updateReplenishmentInput: (
    value: any,
    property: keyof ReplenishmentCreateInput,
  ) => void;
  onCreate?: () => void;
  onCancel?: () => void;
}

const ReplenishmentForm = ({
  assetUnit,
  totalOrdered,
  totalReceived,
  replenishmentInputs,
  updateReplenishmentInput,
  onCreate,
  onCancel,
}: Props) => {
  const numberUpdate = (
    value: any,
    property: keyof ReplenishmentCreateInput,
    decimalLimit?: number,
  ) => {
    const newValue =
      Math.abs(Number(value)) > 0 ? Math.abs(Number(value)) : null;

    const cleanedValue =
      decimalLimit && !R.isNil(newValue)
        ? parseFloat(newValue.toFixed(4))
        : newValue;

    updateReplenishmentInput(cleanedValue, property);
  };

  const calculateValue = (property: keyof ReplenishmentCreateInput) => {
    const { quantityReceived, unitCost, totalCost } = replenishmentInputs;

    const totalCostCalc = parseFloat(
      (Number(quantityReceived) * Number(unitCost)).toFixed(DECIMAL_LIMIT),
    );
    const costCalc = parseFloat(
      (Number(totalCost) / Number(quantityReceived)).toFixed(DECIMAL_LIMIT),
    );

    const updatedValue = property === 'totalCost' ? totalCostCalc : costCalc;
    updateReplenishmentInput(updatedValue, property);
  };

  const subtextValue = getSDDOTReplenishmentSubtextOptions(
    totalOrdered,
    totalReceived,
    replenishmentInputs?.quantityReceived,
    assetUnit || '',
  );

  const subtextStyleName =
    subtextValue?.icon === 'warning'
      ? 'remaining-subtext warning'
      : 'remaining-subtext';

  const iconColor =
    subtextValue?.icon === 'warning' ? colors.brand.orange : colors.brand.green;

  const showUnitCostCalculate =
    isNilOrEmpty(replenishmentInputs?.unitCost) &&
    !isNilOrEmpty(replenishmentInputs?.quantityReceived) &&
    !isNilOrEmpty(replenishmentInputs?.totalCost);

  const showTotalCostCalculate =
    isNilOrEmpty(replenishmentInputs?.totalCost) &&
    !isNilOrEmpty(replenishmentInputs?.quantityReceived) &&
    !isNilOrEmpty(replenishmentInputs?.unitCost);

  const createDisabled =
    isNilOrEmpty(replenishmentInputs?.invoiceNumber) ||
    isNilOrEmpty(replenishmentInputs?.quantityReceived) ||
    isNilOrEmpty(replenishmentInputs?.unitCost) ||
    isNilOrEmpty(replenishmentInputs?.totalCost);

  return (
    <div styleName="form-container">
      <div styleName="form-title">Replenishment</div>
      <div styleName="form-subtext">
        The unit cost of this inventory item will be re-calculated by taking
        into account the quantity received and the unit cost of this
        replenishment.
      </div>
      <div styleName="form-input-row">
        <TextField
          InputLabelProps={{ style: styles.label }}
          style={styles.largeInput}
          value={replenishmentInputs?.invoiceNumber}
          label="Invoice Number"
          onChange={event =>
            updateReplenishmentInput(event.target.value, 'invoiceNumber')
          }
        />
        <TextField
          InputLabelProps={{ style: styles.label }}
          style={styles.largeInput}
          type="number"
          value={replenishmentInputs?.quantityReceived || ''}
          label={`Total Quantity Received (${assetUnit || ''})`}
          onChange={event =>
            numberUpdate(event.target.value, 'quantityReceived')
          }
        />
        <TextField
          InputLabelProps={{ style: styles.label }}
          style={styles.smallInput}
          type="number"
          value={replenishmentInputs?.unitCost || ''}
          label="Unit Cost"
          onChange={event =>
            numberUpdate(event.target.value, 'unitCost', DECIMAL_LIMIT)
          }
          InputProps={{
            startAdornment: (
              <Icon style={styles.currencyIcon}>attach_money</Icon>
            ),
          }}
          helperText={
            showUnitCostCalculate && (
              <div
                styleName="calculate-button"
                onClick={() => calculateValue('unitCost')}
              >
                Calculate
              </div>
            )
          }
        />
        <TextField
          InputLabelProps={{ style: styles.label }}
          style={styles.smallInput}
          type="number"
          value={replenishmentInputs?.totalCost || ''}
          label="Total Cost"
          onChange={event =>
            numberUpdate(event.target.value, 'totalCost', DECIMAL_LIMIT)
          }
          InputProps={{
            startAdornment: (
              <Icon style={styles.currencyIcon}>attach_money</Icon>
            ),
          }}
          helperText={
            showTotalCostCalculate && (
              <div
                styleName="calculate-button"
                onClick={() => calculateValue('totalCost')}
              >
                Calculate
              </div>
            )
          }
        />
      </div>
      <div styleName="input-container">
        <TextField
          InputLabelProps={{ style: styles.label }}
          fullWidth
          value={replenishmentInputs?.comments}
          label="Comments (optional)"
          onChange={event =>
            updateReplenishmentInput(event.target.value, 'comments')
          }
        />
      </div>
      {subtextValue && (
        <div styleName={subtextStyleName}>
          <Icon color={iconColor} style={styles.icon}>
            {subtextValue.icon}
          </Icon>
          <div>{subtextValue.message}</div>
        </div>
      )}
      {onCreate && onCancel && (
        <div styleName="create-buttons">
          <Button onClick={onCancel} style={{ marginRight: '0.5rem' }}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onCreate}
            disabled={createDisabled}
          >
            Create
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReplenishmentForm;
